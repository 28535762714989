import * as React from 'react'
import ReactDOM from 'react-dom'
import { firebaseConfig } from 'common/constants'
import { Provider } from 'react-redux'
// import Amplify from 'aws-amplify'
import { AuthProvider } from 'common/contexts/auth'
// import awsconfig from './aws-exports.js';
import store from './rootStore'
import * as serviceWorker from './serviceWorker'
import 'assets/css/nc-css/nc-auth.css'
import 'assets/css/nc-css/nc-common.css'
import 'assets/css/nc-css/nc-theme.css'
import RootProvider from './common/contexts/RootAuthContext'
import firebase from 'firebase/app'
// import 'assets/css/common.css';
// import 'assets/css/theme.css';
// import 'assets/css/solutions-architects.css';

// Init Amplify config
// Amplify.configure({})

firebase.initializeApp(firebaseConfig)

const renderApp = () => {
  const App = require('components/app').default
  ReactDOM.render(
    <Provider store={store}>
      <AuthProvider>
        <RootProvider>
          <App/>
        </RootProvider>
      </AuthProvider>
    </Provider>,
    document.getElementById('root')
  )
}

// Render React App
renderApp()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('components/app', renderApp)
}
