import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUser } from '../store.types'

const ROLES_TABS = [
  { title: 'Users', className: 'red', key: 'users', count: 0 },
  { title: 'Groups', className: 'yellow', key: 'groups', count: 0 },
  { title: 'Clients', className: 'green', key: 'clients', count: 0 },
]

export const SELECT_GROUP = 'SELECT_GROUP'
export const CREATE_GROUP = 'CREATE_GROUP'

export interface IFormData {
  contact: string
  user_email: string
  user_name: string
  user_role: string
  custom_permission: string[] | []
  shift_days: string[] | []
  shift_time: string | null
  reporting_to: string | null
  is_disable?: string
  user_status?: string
}

export interface IClient {
  alternate_contact: string
  alternate_email: string
  company_name: string
  company_size: string
  contact: string
  created_at: string
  customer_account_id: number | null
  permissions: string[]
  reporting_to: string
  service_id: string
  services: string[]
  services_id: string[]
  shift_days: string
  shift_time: string
  team_id: string
  team_name: string
  updated_at: string
  user_description: string
  user_email: string
  user_id: string
  user_job_title: string
  user_name: string
  user_role: string[]
  user_role_id: string
  user_status: string
  user_type: string
}

export const initialUser: IUser = {
  user_id: '',
  service_id: '',
  user_name: '',
  user_description: '',
  user_email: '',
  contact: '',
  alternate_contact: '',
  created_at: '',
  updated_at: '',
  user_job_title: '',
  reporting_to: '',
  user_role: [],
  user_role_id: '',
  team_id: '',
  user_status: '',
  group_name: [],
  team_name: '',
  shift_time: '',
  shift_days: '',
  customer_account_id: 0,
  time_zone: '',
  alternate_email: '',
  alternate_contact_otp_details: '',
  company_name: '',
  company_size: '',
  contact_otp_details: '',
  permissions: [],
  services: [],
  services_id: '',
  user_type: '',
  user_profile_url: ''
}

export interface IUsersRolesReducer {
  loading: boolean,
  users: IUser[],
  tabs: any[]
  user: {
    group_name: string
    group_mode: string
    data: IUser
    formData: IFormData
  }
  groups: {
    data: any[],
  }
  clients: {
    data: IClient[]
  }
  filters: IFilter & {
    company_name: string
    status: string
  }
  clientsLists: string[]
  statusLists: string[]
  servicesLists: any[]
  group_count: number
  user_count: number
  client_count: number
}

export interface IFilter {
  pageid: number
  searchText: string,
  items: number
  sorted_by: string
  sorted_type: string
  userlogged: string
}

export const ROLE_TABLE_COLUMN = [
  { dataIndex: 'user_name', key: 'USER NAME', title: 'User Name', isSorting: true },
  { dataIndex: 'user_email', key: 'EMAIL ID', title: 'Email', isSorting: true },
  { dataIndex: 'group_name', key: 'GROUP', title: 'Group', isSorting: true },
  { dataIndex: 'contact', key: 'CONTACT', title: 'Contact' },
  // { dataIndex: 'reporting_to', key: 'REPORTING TO', title: 'Reporting To' },
  { dataIndex: 'user_status', key: 'STATUS', title: 'Status', isSorting: true },
  { dataIndex: 'action', key: 'ACTION', title: 'Actions' }
]

export const GROUP_TABLE_COLUMN = [
  { dataIndex: 'group_name', key: 'USER NAME', title: 'Group Name', isSorting: true },
  { dataIndex: 'group_type', key: 'GROUP TYPE', title: 'Group Type' },
  { dataIndex: 'users_count', key: 'USER COUNT', title: 'User Count', isSorting: true },
  { dataIndex: 'status', key: 'STATUS', title: 'Status' },
  { dataIndex: 'action', key: 'ACTION', title: 'Actions' }
]

export const CLIENT_TABLE_COLUMNS = [
  { dataIndex: 'user_name', key: 'NAME', title: 'Name', isSorting: true },
  { dataIndex: 'company_name', key: 'COMPANY', title: 'Company', isSorting: true },
  { dataIndex: 'user_email', key: 'EMAIL', title: 'Email', isSorting: true },
  { dataIndex: 'contact', key: 'CONTACT', title: 'Contact' },
  { dataIndex: 'created_at', key: 'CREATED_ON', title: 'Created On', isSorting: true },
  { dataIndex: 'user_status', key: 'STATUS', title: 'Status' },
  { dataIndex: 'action', key: '', title: 'Actions' }
]

const initialState: IUsersRolesReducer = {
  loading: false,
  users: [],
  filters: {
    searchText: '',
    pageid: 1,
    items: 10,
    sorted_by: '',
    sorted_type: '',
    userlogged: '',
    company_name: '',
    status: '',
  },
  user: {
    group_mode: SELECT_GROUP,
    group_name: '',
    data: initialUser,
    formData: {
      contact: '',
      user_email: '',
      user_name: '',
      custom_permission: [],
      reporting_to: '',
      shift_days: [],
      shift_time: '',
      user_role: '',
      user_status: ''
    }
  },
  groups: {
    data: []
  },
  clients: {
    data: []
  },
  tabs: ROLES_TABS,
  clientsLists: [],
  statusLists: [],
  servicesLists: [],
  user_count: 0,
  group_count: 0,
  client_count: 0
}

const UsersRolesSlice = createSlice({
  name: 'usersRoles',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setUsers(state, action: PayloadAction<any[]>) {
      state.users = action.payload
    },
    setTabCount(state, action: PayloadAction<any>) {
      state.user_count = action.payload.user_count || 0
      state.group_count = action.payload.group_count || 0
      state.client_count = action.payload.client_count || 0
      state.tabs = state.tabs.map((t) => {
        switch (t.key) {
          case 'users':
            return { ...t, count: action.payload.user_count || 0 }
          case 'groups':
            return { ...t, count: action.payload.group_count || 0 }
          case 'clients':
            return { ...t, count: action.payload.client_count || 0 }
          default:
            return t
        }
      })
    },
    setGroupsData(state, action: PayloadAction<any>) {
      state.groups.data = action.payload
    },
    setFilters(state, action: PayloadAction<any>) {
      state.filters = { ...action.payload }
    },
    setDefaultFilter(state, action: PayloadAction<null>) {
      state.filters = { ...initialState.filters }
    },
    setClientsData(state, action: PayloadAction<IClient[]>) {
      state.clients.data = action.payload
    },
    setClientsCount(state, action: PayloadAction<number>) {
      state.client_count = action.payload
      state.tabs.map((t) => {
        if (t.key === 'Clients')
          return { ...t, count: action.payload }

        return t
      })
    },
    setUserData(state, action: PayloadAction<IUser>) {
      state.user.data = {
        ...state.user.data,
        ...action.payload
      }
    },
    setFormData(state, action: PayloadAction<any>) {
      state.user.formData = {
        ...state.user.formData,
        custom_permission: action.payload.permissions,
        ...action.payload
      }
    },
    setDefaultFormData(state, action: PayloadAction) {
      state.user.formData = {
        contact: '',
        user_email: '',
        user_name: '',
        custom_permission: [],
        reporting_to: '',
        shift_days: [],
        shift_time: '',
        user_role: ''
      }
    },
    setGroupMode(state, action: PayloadAction<string>) {
      state.user.group_mode = action.payload
    },
    setGroupName(state, action: PayloadAction<string>) {
      state.user.group_name = action.payload
    },
    clearGroupInfo(state, action: PayloadAction<null>) {
      state.user.group_name = ''
      state.user.group_mode = SELECT_GROUP
    },
    upsertCustomPermission(state, action: PayloadAction<{ name: string, checked: boolean }>) {
      let { custom_permission } = state.user.formData
      const index = custom_permission.findIndex((o => o === action.payload.name))
      if (index === -1 && action.payload.checked) {
        custom_permission = [...custom_permission, action.payload.name]
      }

      if (index !== -1 && !action.payload.checked) {
        custom_permission.splice(index, 1)
      }

      state.user.formData.custom_permission = custom_permission || []
    },
    setClientsLists(state, action: PayloadAction<string[]>) {
      state.clientsLists = action.payload
    },
    setStautsLists(state, action: PayloadAction<string[]>) {
      state.statusLists = action.payload
    },
    setServicesLists(state, action: PayloadAction<any[]>) {
      state.servicesLists = action.payload
    },
    clearAllPermissions(state, action: PayloadAction<null>) {
      state.user.formData.custom_permission = []
    },
    selectAllPermissions(state, action: PayloadAction<string[]>) {
      state.user.formData.custom_permission = action.payload
    },
  }
})

export const {
  setLoading,
  setUsers,
  setTabCount,
  setFilters,
  setGroupName,
  setGroupMode,
  setGroupsData,
  clearGroupInfo,
  setDefaultFormData,
  setFormData,
  upsertCustomPermission,
  setUserData,
  setClientsData,
  setStautsLists,
  setClientsLists,
  setServicesLists,
  clearAllPermissions,
  selectAllPermissions,
} = UsersRolesSlice.actions


export default UsersRolesSlice.reducer
