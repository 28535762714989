import React from 'react'

interface Props {
  tag?: keyof JSX.IntrinsicElements;
  style?: any
  className?: string
}

export const Headline: React.FC<Props> = ({ tag, children, ...rest }) => {
  const Wrapper: any = tag as 'div'
  return <Wrapper {...rest}>{children}</Wrapper>
}

Headline.defaultProps = {
  tag: 'h1',
}

export default Headline
