import React from 'react'
import cx from 'classnames'
import Spin from '../spin'
import './NCButton.css'

export type TButtonType = 'primary' | 'secondary' | 'cancel' | 'primary-outline' | 'secondary-outline'

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  color?: TButtonType;
  loaderClass?: string;
  outline?: boolean;
  loading?: boolean;
  iconButton?: boolean;
  outlineColor?: string;
  full?: boolean;
  notAllowed?: boolean
  type?: 'button' | 'submit' | 'reset' | undefined;
}

const NCButton: React.FC<ButtonProps> =
  ({
     className,
     children,
     onClick,
     color,
     style,
     outline,
     loading,
     disabled,
     full,
     type,
     iconButton,
     notAllowed,
     loaderClass
   }): React.ReactElement => {

    const buttonClasses = cx(className, 'ncc-button', {
      primary: color === 'primary',
      secondary: color === 'secondary',
      cancel: color === 'cancel',
      'primary-outline': color === 'primary-outline',
      'secondary-outline': color === 'secondary-outline',
      [`${color}-outline`]: outline,
      full: full,
      'nc-action-button': iconButton,
      disabled: disabled,
      'not-allowed': notAllowed
    })

    let component = (
      <button
        className={cx(buttonClasses)}
        onClick={onClick}
        disabled={disabled}
        style={style}
        type={type}
      >
        {children}
        {loading && <Spin small={true}/>}
      </button>
    )

    if (loading) {
      component = <div className={`ncc-button-loading load-inline ${loaderClass}`}>{component}</div>
    }

    return component

  }

export default NCButton