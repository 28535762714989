import cx from 'classnames'
import React from 'react'
import Spin from 'components/nCall/spin'
import { EyeIconNew, EyeNonViewIcon, SmallCloseIcon } from '../icons'
import './input.css'

export interface InputProps extends React.HTMLProps<HTMLInputElement> {
  prefix?: any;
  suffix?: any;
  error?: string;
  loading?: boolean;
  labelClass?: string
  className?: string
  inputWraper?: string
  isPasswordToggle?: boolean
  onPasswordToggle?: () => void
  onClear?: () => void
  v2?: boolean
}

const Input: React.FC<InputProps> =
  ({
    label,
    error,
    prefix,
    inputWraper,
    className,
    loading,
    required,
    suffix,
    isPasswordToggle,
    onPasswordToggle,
    onClear,
    v2,
    ...props
  }) => {

    let component = (
      <>
        <input
          className={cx('nc-input', className, {
            error,
          })}
          {...props}
        />
        {error && <div className='error'>{error}</div>}
      </>
    )

    if (loading) {
      component = (
        <>
          {component}
          <Spin small={true} />
        </>
      )
    }

    if (prefix || label || onClear || suffix || isPasswordToggle) {
      component = (
        <div className={cx(v2 ? 'ncc-input-group' : 'nc-input-group', prefix && 'nc-input-icon', inputWraper, { 'nc-toggle-password': isPasswordToggle })}>
          {label && (
            <label className={`nc-label ${props.labelClass}`}>
              {label}
              {required && <span style={{ color: 'red' }}>&nbsp;*</span>}
            </label>
          )}
          {prefix && (
            <i className='nc-svg-icon-prefix'>{prefix}</i>
          )}
          {suffix && (
            
            <i/>
          )}
          {isPasswordToggle && (
            <i onClick={onPasswordToggle} className='nc-password-view-icon'>
              {props.type === 'text' ? <EyeIconNew /> : <EyeNonViewIcon />}
            </i>
          )}
          {
            onClear && props?.value !== '' && (
              <i onClick={onClear} className='nc-svg-icon-clear'><SmallCloseIcon /></i>
            )
          }
          {component}
        </div>
      )
    }

    return component
  }

Input.defaultProps = {
  type: 'text',
}

export default Input
