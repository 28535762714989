import { combineReducers } from '@reduxjs/toolkit'
import incidentListsReducer from 'store/reducers/incidents.reducer'
import incidentDetailsReducer from 'store/reducers/incidentDetails.reducer'
import runbookReducer from 'store/reducers/runbook.reducers'
import authReducer from 'store/reducers/auth.reducer'
import userRolesReducer from 'store/reducers/usersRoles.reducer'
import MiscReducer from 'store/reducers/misc.reducer'
import IntegrationsReducer from 'store/reducers/integrations.reducer'
import ReportsReducer from 'store/reducers/reports.reducer'
import NotificationsReducer from 'store/reducers/notification.reducer'
import ProfileReducers from 'store/reducers/profile.reducer'
import ScheduleReducers from 'store/reducers/schedule.reducer'
import EscalationPolicyReducer from 'store/reducers/escalation.reducer'

const rootReducer = combineReducers({
  auth: authReducer,
  incidents: incidentListsReducer,
  incidentDetails: incidentDetailsReducer,
  runbook: runbookReducer,
  userRoles: userRolesReducer,
  misc: MiscReducer,
  integrations: IntegrationsReducer,
  reports: ReportsReducer,
  notifications: NotificationsReducer,
  profile: ProfileReducers,
  schedule: ScheduleReducers,
  escalation: EscalationPolicyReducer
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
