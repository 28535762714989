import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import _set from 'lodash/set'
import _get from 'lodash/get'
import randomcolor from 'randomcolor'
import { ScheduleViewType } from 'store/store.types'

interface initialStateI {
  loading: boolean;
  schedules: ScheduleDataI[];
  addScheduleForm: {
    time_zone: string
    name: string
    waves: AddWaveFormI[]
    viewDate: string
    viewType: ScheduleViewType
  }
  waveForm: AddWaveFormI
}

export interface ScheduleDataI {
  name: string
  schedule_id: number
  time_zone: string
  created_by: string
  created_at: string
  waves: AddWaveFormI[]

}

const initialRestrictDay = [
  {
    id: Date.now(),
    from_time: '',
    to_time: '',
  },
]

export interface AddWaveFormI {
  id?: number;
  users: any[];
  rotation_type: string;
  is_shift_restricted: boolean;
  restricted_shifts_by: 'day' | 'week' | '';
  restricted_shifts: any[];
  start_date_time: string;
  color?: string
}

const initialRestrictWeek = [
  {
    id: Date.now(),
    from_day: '',
    from_time: '',
    to_day: '',
    to_time: '',
  },
]

const initialwaveForm: AddWaveFormI = {
  id: Date.now(),
  is_shift_restricted: true,
  restricted_shifts: [{
      id: Date.now(),
      from_time: '',
      to_time: '',
  }],
  restricted_shifts_by: 'day',
  rotation_type: 'weekly',
  users: [],
  start_date_time: '',
}

export const WAVE_TABLE_COLUMNS = [
  { dataIndex: 'users', key: 'users', title: 'User' },
  { dataIndex: 'on_call_rotation', key: 'On Call Rotation', title: 'On Call Rotation' },
  { dataIndex: 'restricted_shifts', key: 'restricted_shifts', title: 'Restrict Shift' },
  { dataIndex: 'start_date_time', key: 'Start Date', title: 'Start Date' },
  { dataIndex: 'action', key: 'Actions', title: 'Actions' },
]

const initialState: initialStateI = {
  schedules: [],
  loading: false,
  waveForm: initialwaveForm,
  addScheduleForm: {
    name: '',
    time_zone: '',
    viewDate: moment().format('LLL'),
    viewType: ScheduleViewType.DAY,
    waves: []
  }
}

const ScheduleReducer = createSlice({
  name: 'Schedule',
  initialState,
  reducers: {
    handleFormChange(state, action: PayloadAction<any>) {
      const { name, value } = action.payload
      state.waveForm = {
        ...state.waveForm,
        [name]: value,
      }
    },
    setInitialRestrict(state, action: PayloadAction<'day' | 'week'>) {
      if (action.payload === 'day') {
        state.waveForm.restricted_shifts = initialRestrictDay
      }
      if (action.payload === 'week') {
        state.waveForm.restricted_shifts = initialRestrictWeek
      }
    },
    addRestrictRow(state, action: PayloadAction<'day' | 'week'>) {
      if (action.payload === 'day') {
        state.waveForm.restricted_shifts = [
          ...state.waveForm.restricted_shifts,
          {
            id: Date.now(),
            from_time: '',
            to_time: '',
          },
        ]
      } else {
        state.waveForm.restricted_shifts = [
          ...state.waveForm.restricted_shifts,
          {
            id: Date.now(),
            from_day: '',
            from_time: '',
            to_day: '',
            to_time: '',
          },
        ]
      }
    },
    handleChangeRestrictRow(
      state,
      action: PayloadAction<{ ind: number; data: any }>
    ) {
      const { ind, data } = action.payload
      const payload = _get(state, `waveForm.restricted_shifts[${ind}]`)
      _set(state, `waveForm.restricted_shifts[${ind}]`, {
        ...payload,
        ...data,
      })
    },
    removeRestrictRow(state, action: PayloadAction<number>) {
      let restrict_rows = state.waveForm.restricted_shifts.slice()
      restrict_rows.splice(action.payload, 1)
      state.waveForm.restricted_shifts = restrict_rows
    },
    setwaveFormInitialState(state, action: PayloadAction) {
      state.waveForm = { ...initialwaveForm }
    },
    handleScheduleFormChange(state, action: PayloadAction<any>) {
      state.addScheduleForm = {
        ...state.addScheduleForm,
        ...action.payload
      }
    },
    addWave(state, action: PayloadAction<AddWaveFormI>) {
      let waves = state.addScheduleForm.waves.slice()
      waves.push({ ...action.payload, color: randomcolor(), id: Date.now() })
      state.addScheduleForm.waves = waves
    },
    removeWave(state, action: PayloadAction<number>) {
      let waves = state.addScheduleForm.waves.slice()
      waves.slice(action.payload, 1)
      state.addScheduleForm.waves = waves
    },
    setWaveToEdit(state, action: PayloadAction<AddWaveFormI>) {
      state.waveForm = { ...state.waveForm, ...action.payload }
    },
    editWave(state, action: PayloadAction<AddWaveFormI>) {
      const newWaves = state.addScheduleForm.waves.slice()
      const ind = state.addScheduleForm.waves.findIndex(o => o.id === action.payload.id)
      newWaves.splice(ind, 1, { ...action.payload })
      state.addScheduleForm.waves = newWaves
    },
    deleteWave(state, action: PayloadAction<number>) {
      const newWaves = state.addScheduleForm.waves.slice()
      const ind = state.addScheduleForm.waves.findIndex(o => o.id === action.payload)
      newWaves.splice(ind, 1)
      state.addScheduleForm.waves = newWaves
    },
    setScheduleFormData(state, action: PayloadAction<any>) {
      state.addScheduleForm = {
        ...state.addScheduleForm, ...action.payload,
      }
    },
    saveSchedules(state, action: PayloadAction<any[]>) {
      state.schedules = action.payload
    },
    resetScheduleForm(state, action: PayloadAction) {
      state.addScheduleForm = initialState.addScheduleForm
    }
  },
})

export const {
  handleFormChange,
  setInitialRestrict,
  addRestrictRow,
  handleChangeRestrictRow,
  removeRestrictRow,
  setwaveFormInitialState,
  handleScheduleFormChange,
  addWave,
  editWave,
  deleteWave,
  removeWave,
  setWaveToEdit,
  setScheduleFormData,
  saveSchedules,
  resetScheduleForm
} = ScheduleReducer.actions

export default ScheduleReducer.reducer
