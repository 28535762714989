import firebase from 'firebase/app'
import 'firebase/messaging'
import { PUBLIC_URL, vapidKey } from 'common/constants'
import axios from 'axios'

const messaging = firebase.messaging()

const getBrowserName = () => {
  const {userAgent} = window.navigator
  if (userAgent.includes('Chrome/')) {
    return 'chrome'
  } else if (userAgent.includes('Firefox/')) {
    return 'firefox'
  } else if (userAgent.includes('AppleWebKit/') && userAgent.includes('Safari/')) {
    return 'safari'
  } else if (userAgent.includes('Edg/')) {
    return 'edge'
  } else {
    return 'other'
  }
}

export const getToken = (user_id: string, callback: (allowed: boolean) => void) => {
  return messaging.getToken({vapidKey: vapidKey}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken)
      console.log('current user_id for client: ', user_id)
      axios.post(`${PUBLIC_URL}/push-notification/fcm-token`, {
        token: currentToken,
        user_id,
        browser: getBrowserName()
      }).then((res) => {
        console.log('res.data', res.data)
        callback(true)
      }).catch((err) => {
        console.error('Error sending token to FCM', err)
      })
      // setTokenFound(true)
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.')
      // setTokenFound(false)
      // shows on the UI that permission is required
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err)
    callback(false)
    // catch error while creating client token
  })
}

export default messaging



