import React from 'react'
import Dialog from 'rc-dialog'
import { CloseWhiteIcon } from 'components/ui/icons'
import 'rc-dialog/assets/index.css'
import './modal.css'

interface IModal {
  show: boolean
  title: string
  loading?: boolean
  bodyClass?: string
  styles?: any
  bodyStyle?: any
  onClose: () => void
  submitTitle?: string
  maskClosable?: boolean
  onSubmit?: () => void
  footer?: any
  className?: string
}

const Modal: React.FC<IModal> = (props): React.ReactElement => {

  const {
    title, onClose, className, bodyStyle, maskClosable,
    footer, styles = {}
  } = props

  return (
    <Dialog
      visible={props.show}
      title={title}
      className={className}
      bodyStyle={bodyStyle}
      onClose={onClose}
      animation='zoom'
      style={styles}
      maskAnimation='fade'
      maskClosable={maskClosable}
      footer={footer}
      closeIcon={<CloseWhiteIcon/>}
    >
      {props.children}
    </Dialog>
  )
}

export default Modal
