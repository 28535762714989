import cx from 'classnames'
import React from 'react'
import './col.css'

interface ColProps {
  className?: string;
  lg?: number;
  md?: number;
  sm?: number;
  xs?: number;
  span?: number;
  right?: boolean
  style?: any
}

const Col: React.FC<ColProps> = ({ className,right, style = {}, children, lg, md, sm, xs }) => {
  const colClasses = cx(className, {
    [`nclg-${lg}`]: lg,
    [`ncmd-${md}`]: md,
    [`ncsm-${sm}`]: sm,
    [`ncxs-${xs}`]: xs,
    'right': right
  })
  return <div className={colClasses} style={style}>{children}</div>
}

Col.defaultProps = {}

export default Col
