import React from 'react'
import NCModal from 'components/nCall/NCModal'
import styled from 'styled-components'

const EditSection = styled.div`
  min-height: 400px;
  padding: 20px;

  textarea {
    height: 400px;
    line-height: 1.5;
    margin-bottom: 10px;
  }

  .ql-editor {
    min-height: 400px;
    margin-bottom: 10px;
  }
`

interface EditRunbookModalI {
  show: boolean
  onClose: () => void
  title?: string
}

const EditRunbookModal: React.FC<EditRunbookModalI> = (props): React.ReactElement => {
  const {show, onClose, children, title = ''} = props

  return (
    <NCModal
      maskClosable={false}
      className={'nc-edit-runbook-modal'}
      show={show}
      title={title}
      onClose={onClose}
    >
      <EditSection>
        {children}
      </EditSection>
    </NCModal>
  )
}

export default EditRunbookModal