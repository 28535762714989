import cx from 'classnames'
import React from 'react'
import './alert.css'

interface Props {
  type: 'error' | 'success' | 'warning' | 'info';
  message?: string;
  description?: string | null;
}

export const Alert: React.FC<Props> = ({ type, message, description }) => {
  const classNames = cx('nc-alert', {
    [`nc-alert-${type}`]: type,
  })

  return (
    <div className={classNames}>
      <div className='nc-alert-message'>{message}</div>
      <div className='nc-alert-description'>{description}</div>
    </div>
  )
}

Alert.defaultProps = {
  type: 'error',
}

export default Alert
