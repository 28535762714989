import React from 'react'

interface Props {
  expand?: boolean
  expandElement?: React.ReactElement
  colSpan?: number
  className?: string
  onMouseOver?: (event: React.MouseEvent<HTMLTableRowElement>) => void
  onMouseOut?: (event: React.MouseEvent<HTMLTableRowElement>) => void
  onClick?: () => void
  onContextMenu?: any
  style?: any
}

export const Tr: React.FC<Props> = props => {
  const { colSpan, expand, expandElement, ...rest } = props

  return (
    <>
      <tr {...rest} />
      {expand &&
        <tr>
          <td colSpan={colSpan}>
            {expandElement}
          </td>
        </tr>
      }

    </>
  )
}

export default Tr
