import React, { useRef, useState } from 'react'
import useOutsideClick from 'components/layout/header/useOutsideClick'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

interface IViewImage {
  src: string
  isMultiple?: boolean
  onClose: () => void
  images?: string[]
}


const ViewImage: React.FC<IViewImage> =
  ({
     src,
     onClose,
     isMultiple = false,
     images = []
   }): React.ReactElement => {
    const ref = useRef(null)
    const [photoIndex, setPhotoIndex] = useState(0)

    useOutsideClick(ref, () => {
      onClose()
    })

    if (isMultiple) {
      return (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => onClose()}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)

          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )
    }

    return (
      <Lightbox
        wrapperClassName={'index-z-full'}
        mainSrc={src}
        onCloseRequest={() => onClose()}
      />
    )
  }

export default ViewImage
