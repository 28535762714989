import React from 'react'
import { Link } from 'react-router-dom'
import _capitalize from 'lodash/capitalize'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _unset from 'lodash/unset'
import findKey from 'lodash/findKey'
import _isArray from 'lodash/isArray'
import _isObject from 'lodash/isObject'
import NCTooltip from 'components/nCall/NCTooltip'
import moment, { Moment } from 'moment'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import Timezone from 'components/timezone'
import { IRunbookColumns } from 'store/store.types'

export const DEFAULT_PROFILE_URL = 'http://www.gravatar.com/avatar/e0026290dd527af8a4bfda00639ed886.jpg?s=100&amp;d=mm'

export const DATA_DOG_KEYS = [
  { key: 'details.title', title: 'title' },
  { key: 'description', title: 'description' },
  { key: 'details.tags', title: 'tags' },
  { key: 'details.body', title: 'details' },
  { key: 'client', title: 'client' },
  { key: 'details.priority', title: 'priority' },
  { key: 'details.query', title: 'query' },
  { key: 'client_url', title: 'monitor status' },
  { key: 'cef_details.contexts', title: 'Images' }
]


export const NEWRELICE_SOURCE_KEYS = [
  { key: 'details.account_name', title: 'account name' },
  { key: 'details.condition_name', title: 'condition name' },
  { key: 'details.current_state', title: 'current state' },
  { key: 'details.details', title: 'details' },
  { key: 'details.duration', title: 'duration' },
  { key: 'details.event_type', title: 'event type' },
  { key: 'details.incident_url', title: 'incident url' },
  { key: 'details.open_violations_count.critical', title: 'open violation count (critical)' },
  { key: 'details.open_violations_count.warning', title: 'open violation count (warning)' },
  { key: 'details.policy_name', title: 'policy name' },
  { key: 'details.policy_url', title: 'policy url' },
  { key: 'details.severity', title: 'severity' },
  { key: 'details.targets', title: 'target' },
  { key: 'details.violation_callback_url', title: 'violation callback url' },
  { key: 'details.violation_chart_url', title: 'violation chart url' },
]

export function getLinkWithToolTip(data: any, isIncident?: boolean) {
  if (!data) return <></>
  const { created_at, incident_number, title = '' } = data
  return (
    <>
      <Timezone date={created_at} isIncidents={isIncident} />&nbsp;
      <NCTooltip overlay={title || ''}>
        <Link to={`/incidents/${incident_number}?mainTab=alerts&subTab=details`}>
          #{incident_number}
        </Link>
      </NCTooltip>
    </>
  )
}

export const getLocationSearch = (queryString: string): string => {
  if (queryString.charAt(0) === '?')
    return queryString.substr(1)

  return queryString
}

export const formatGMTToLocal = (date: string = Date.now().toString()): any => {
  if (!date.includes('GMT')) {
    date = date.concat(' GMT')
  }
  return moment(date)
}

export const formatDate = (date: Moment): string => {
  // const gerAbbr =
  // const zone_name =  _moment.tz.guess()
  // const timezone = _moment.tz(zone_name).zoneAbbr()
  return date.format('YYYY-MM-DD LT')
}

export const toBase64 = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export const validateJsonToJS = (data: string = '{}'): any => {
  try {
    // remove invalid ascii character from JSON string.
    const jsObj = JSON.parse(data.replace(/[^\x20-\x7E]/g, ''))
    if (jsObj)
      return jsObj
    return {}
  } catch (e) {
    console.error('Invalid json on page.', e)
    return {}
  }
}

export const formatPhoneNumber = (number: string) => {
  const phoneNumber = parsePhoneNumberFromString(`+${number}`)
  if (phoneNumber) {
    return phoneNumber.formatInternational()
  }
  return number
}

export const isContactValid = (number: string) => {
  const phoneNumber = parsePhoneNumberFromString(`+${number}`)
  if (phoneNumber) {
    return phoneNumber.isValid()
  }
  return false
}

export const processParams = (rawParams: any = {}) => {
  const _params = { ...rawParams }
  if (_params.keys_search && typeof _params.keys_search === 'string') {
    _params.keys_search = [_params.keys_search]
  }
  if (_params.values_search && typeof _params.values_search === 'string') {
    _params.values_search = [_params.values_search]
  }
  if (_params.tags_search && typeof _params.tags_search === 'string') {
    _params.tags_search = [_params.tags_search]
  }

  return _params
}

export const formatSearchObjectByTags = (tags: string[]) => {
  const obj: any = {}
  tags.forEach((t) => {
    if (t.trim().startsWith('@alert:')) {
      obj.alert_search = t.replace(/^@alert:/, '')
    } else if (t.trim().startsWith('@remediation:')) {
      obj.remediation_search = t.replace(/^@remediation:/, '')
    } else if (t.trim().startsWith('@cause:')) {
      obj.cause_search = t.replace(/^@cause:/, '')
    } else if (t.trim().startsWith('@text:')) {
      obj.contents_search = t.replace(/^@text:/, '')
    } else if (t.trim().startsWith('@tags:')) {
      obj.tags_search = t.replace(/^@tags:/, '')
    }
  })
  return obj
}

export const removeDialCode = (value: string, dialCode: string) => {
  if (value.startsWith(dialCode, 0)) {
    return value.replace(dialCode, '')
  }
  return value
}

export const bindColumnWidth = (columns: IRunbookColumns[]) => {
  try {
    const tempColumns: any[] = JSON.parse(localStorage.getItem('columns') || '[]')
    const _columns = columns.map((c) => {
      const matchedColumn = tempColumns.find((t) => t.key === c.key)
      if (matchedColumn) {
        return {
          ...c,
          width: matchedColumn.width
        }
      } else {
        return {
          ...c,
          width: 200
        }
      }
    })
    return _columns

  } catch (err) {
    console.error('column width binding error', err)
    return columns
  }
}

export const isUrl = (s: string) => {
  const regexp = /^(ftp|http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@\-/]))?/
  return regexp.test(s)
}

export const isImageUrl = (s: string) => {
  return s.endsWith('.png') || s.endsWith('.jpeg') || s.endsWith('.jpg')
}

export const getDataDogData = (data: any): object => {
  let obj: object = {}
  DATA_DOG_KEYS.forEach(({ title, key }: { key: string, title: string }) => {
    _set(obj, title, _get(data, key))
  })
  return obj
}

export const getNewRelicData = (data: any): any => {
  let obj: object = {}
  NEWRELICE_SOURCE_KEYS.forEach(({ title, key }: { key: string, title: string }) => {
    _set(obj, title, _get(data, key))
  })
  return obj
}

export const convertNestedToOneObject = (data: object) => {
  try {
    let obj = {}
    for (const [key, value] of Object.entries(data)) {
      if
        (
        typeof value === 'string' || typeof value === 'boolean' ||
        typeof value === 'number' || value === null
      ) {
        obj = { ...obj, [key]: value }
      } else if (_isArray(value) && value.length > 0) {
        console.log('isArray ===>>', value)
        const temp = convertNestedToOneObject(value[0]) || {}
        obj = { ...obj, ...temp }
      } else if (_isObject(value)) {
        const temp = convertNestedToOneObject(value)
        obj = { ...obj, ...temp }
      } else {
        console.log('something else/.........', key, value)
      }
    }
    return obj
  } catch (err) {
    return data
  }
}

export const removeSameDatafromObject = (data: object) => {
  try {
    for (const [key, value] of Object.entries(data)) {
      const _key = findKey(data, d => d === value)
      if (_key && _key !== key) {
        _unset(data, key)
      }
    }
    return data
  } catch (err) {
    console.error('Error removeSameDataFromObject==', err)
    return data
  }
}

export const joinArrayTostring = (arr: string[]): string => {
  let str = '['
  if (arr.length > 0) {
    arr.forEach((ar, index) => {
      if (arr.length - 1 !== index)
        str = str.concat(`"${ar}",`)
      else
        str = str.concat(`"${ar}"]`)
    })
    return str
  } else {
    return ''
  }
}

export const stringToArray = (str: string): string[] => {
  if (!str) {
    return []
  } else {
    return JSON.parse(str)
  }
}

export const isEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const isPasswordValid = (password: string) => {
  const PASSWORD_REGEX = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?"!@#%&/,><\':;|_~`^\\]\\[\\)\\(]).{8,}')
  return password.match(PASSWORD_REGEX)
}

export const isStartTimeGreater = (day_time_start: string, day_time_end: string): boolean => { //time format = 5:00 PM
  const strStartTime = moment(day_time_start, 'HH:mm:00').format('hh:mm A')
  const strEndTime = moment(day_time_end, 'HH:mm:00').format('hh:mm A')
  let startTime: any = new Date().setHours(GetHours(strStartTime), GetMinutes(strStartTime), 0)
  let endTime: any = new Date(startTime)
  endTime = endTime.setHours(GetHours(strEndTime), GetMinutes(strEndTime), 0)
  return startTime > endTime
}

function GetHours(d: string) {
  let h = parseInt(d.split(':')[0])
  if (d.split(':')[1].split(' ')[1] === 'PM') {
    h = h + 12
  }
  return h
}

function GetMinutes(d: string) {
  return parseInt(d.split(':')[1].split(' ')[0])
}

export function getFormatedDate(date: string) {
  try {
    return moment(date).format('YYYY-MM-DD')
  } catch (e) {
    return date
  }
}

export function isValidStartAndEndDate(start_date: Date | null | string, end_date: Date | null | string): string {
  const startDate = moment(start_date || '')
  const endDate = moment(end_date || '')
  if ((start_date && !end_date) || (!start_date && end_date)) {
    return 'Please select both date or remove it.'
  } else if ((startDate.isValid() && endDate.isValid()) && endDate.isBefore(startDate)) {
    return 'Start date should be less than End date.'
  } else if ((start_date && end_date) && (!startDate.isValid() || !endDate.isValid())) {
    return 'Please add valid date.'
  } else if (startDate.isBefore(moment().subtract(1, 'days')) || endDate.isBefore(moment().subtract(1, 'days'))) {
    return 'Start date and end date should not be less than today\'s date.'
  } else {
    return ''
  }
}

export const isValidImportData = (rows: any[]): boolean => {
  if (rows.length > 0) {
    const row = rows[0]
    return row.hasOwnProperty('alert') &&
      row.hasOwnProperty('cause') &&
      row.hasOwnProperty('remediation') &&
      row.hasOwnProperty('priority') &&
      row.hasOwnProperty('tags')
  } else {
    return false
  }
}
export const isHaveValidPriority = (rows: any[]): boolean => {
  if (rows.length > 0) {
    const __rows =
      rows.filter(r => r.priority !== '' && r.priority.toLowerCase() !== 'high' && r.priority.toLowerCase() !== 'low')
    return !(__rows.length > 0)
  }
  return false
}

export const formatCSVData = (rows: any[]) => {
  try {
    return rows.map(r => (
      {
        ...r,
        tags: formatTags(r.tags || ''),
        priority: _capitalize(r.priority)
      }
    ))
  } catch (e) {
    return rows
  }
}

export const formatTags = (tags: string = '') => {
  return tags.split('|').filter(t => !!t.trim())
}

export const formatTime = (date: string) => {
  if (date) {
    return moment(date, 'HH:mm:00').format('hh:mm A')
  }
  return ''
}
