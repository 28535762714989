import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IMisc {
  image: string
  images: string[]
  isMultiple: boolean
}

let initialState: IMisc = {
  image: '',
  images: [],
  isMultiple: false
}

const MiscReducer = createSlice({
  name: 'Misc',
  initialState,
  reducers: {
    setImage(state, action: PayloadAction<string>) {
      state.image = action.payload
      state.isMultiple = false
      state.images = []
    },
    setMultiple(state, action: PayloadAction<{ isMultiple: boolean, images: string[] }>) {
      state.isMultiple = action.payload.isMultiple
      state.images = action.payload.images
      state.image = ''
    }
  }
})

export const {
  setImage,
  setMultiple
} = MiscReducer.actions

export default MiscReducer.reducer
