import * as React from 'react'
import clsx from 'classnames'
import { CloseIcon } from '../icons'
import { toast } from 'react-toastify'
import { Button } from '../'
import './toast.css'

interface ToastPropsI {
  message: string
  title?: string
  actionText?: string
  type?: string
  options?: any
  time?: number
  onActionClick?: () => void
}

const autoClose = 4000 // time to display toast
const toastBodyClassname = 'c-toast-body'


const Toast: React.FC<ToastPropsI> = (props): React.ReactElement => {
  const {message, actionText, type = 'success', onActionClick, title} = props

  return (
    <div className={clsx('toast-container', type)}>
      {title && <span className='toast-title'>{title}</span>}
      <button className='icon-button close' title='Close'>
        <i className='np-svgIcon'>
          <CloseIcon/>
        </i>
      </button>
      <p>
        {message}
      </p>
      {
        actionText &&
        <Button onClick={onActionClick} className='blue'>
          {actionText}
        </Button>
      }
    </div>
  )
}

export const notification = {
  success: ({options, message, actionText, type, onActionClick, time}: ToastPropsI) => {
    toast(
      <Toast
        message={message}
        type='success'
        actionText={actionText}
        onActionClick={onActionClick}
      />, {
        hideProgressBar: true,
        bodyClassName: toastBodyClassname,
        autoClose: time || autoClose,
        closeButton: false,
        className: `c-toast-container success`
      })
  },
  error: ({options, message, actionText, type, onActionClick, time}: ToastPropsI) => {
    toast(
      <Toast
        message={message}
        type='error'
        actionText={actionText}
        onActionClick={onActionClick}
      />, {
        hideProgressBar: true,
        autoClose: time || autoClose,
        closeButton: false,
        className: `c-toast-container danger`
      })
  },
  warning: ({options, message, actionText, type, onActionClick, time}: ToastPropsI) => {
    toast(
      <Toast
        message={message}
        type='warning'
        actionText={actionText}
        onActionClick={onActionClick}
      />, {
        hideProgressBar: true,
        autoClose: time || autoClose,
        closeButton: false,
        className: 'c-toast-container warning'
      })
  },
  push: ({options, title, message, actionText, type, onActionClick, time}: ToastPropsI) => {
    toast(
      <Toast
        title={title}
        message={message}
        type='push'
        actionText={actionText}
        onActionClick={onActionClick}
      />, {
        hideProgressBar: true,
        autoClose: false,
        closeButton: false,
        className: 'c-toast-container push',
        position: toast.POSITION.TOP_RIGHT
      })
  }
}

export default Toast
