import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IncidentsI } from '../store.types'

// import _remove from 'lodash/remove'

export interface incidentDetailsReducer {
  data: IncidentsI;
  loading: boolean
}

let initialState: incidentDetailsReducer = {
  data: {
    number: '',
    title: '',
    status: '',
    priority: '',
    severity: '',
    created_at: '',
    updated_at: '',
    client_name: '',
    summary: '',
    similar_incidents: [],
    notes: [],
    assigned_to: [],
    assigned_by: '',
    has_note: 0,
    client_id: ''

  },
  loading: false,
}
const IncidentsDetailsSlice = createSlice({
  name: 'jobDetails',
  initialState,
  reducers: {
    setIncidentsdetails(state, action: PayloadAction<IncidentsI>) {
      state.data = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setInitialState(state, action: PayloadAction<any>) {
      state.loading = false
      state.data = action.payload
    }
  }
})

export const {

  setIncidentsdetails,
  setLoading
} = IncidentsDetailsSlice.actions

export default IncidentsDetailsSlice.reducer
