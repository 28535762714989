import React from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import Spin from 'components/nCall/spin'
import { SmallCloseIcon } from 'components/ui/icons'
import './NCInput.css'

export const NCInputLabel = styled.label`
  font-weight: 600;
  line-height: 1.38;
  color: #11253d;
`

export interface NCInputProps extends React.HTMLProps<HTMLInputElement> {
  prefix?: any;
  suffix?: any;
  onSuffixClick?: any
  error?: string;
  loading?: boolean;
  labelClass?: string
  className?: string
  inputWraper?: string
  onClear?: () => void
}

const NCInput: React.FC<NCInputProps> =
  ({
    label,
    error,
    prefix,
    suffix,
    inputWraper,
    className,
    loading,
    required,
    size,
    onSuffixClick = () => {
    },
    onClear,
    ...props
  }): React.ReactElement => {

    const renderSuffix = () => {
      if (loading)
        return <Spin small={true} />

      if (suffix) {
        return <span onClick={onSuffixClick} className='input-suffix'>{suffix}</span>
      }
      return null
    }

    const renderClearIcon = () => {
      if (props.value === '' || onClear === undefined) return null
      return <i
        tabIndex={1}
        className='ncc-input-clear-icon'
        onClick={onClear}
      >
        <SmallCloseIcon />
      </i>
    }


    let component = (
      <>
        <input
          className={cx('ncc-input', className, {
            error,
            'input-x-icon': props.value !== '' || onClear !== undefined

          })}
          {...props}
        />
        {error && <div className='error'>{error}</div>}
      </>
    )

    if (loading) {
      component = (
        <>
          {component}
          <Spin small={true} />
        </>
      )
    }

    if (prefix || label || suffix || onClear) {
      component = (
        <div
          className={
            cx(
              'ncc-input-group',
              prefix && 'ncc-input-icon',
              suffix && 'ncc-input-suffix',
              loading && 'ncc-input-loading',
              inputWraper,
              label && 'ncc-input-label'

            )
          }
        >
          {label && (
            <NCInputLabel>
              {label}
              {required && <span style={{ color: 'red' }}>*</span>}
            </NCInputLabel>
          )}
          {prefix && (
            <i className='ncc-svg-icon'>{prefix}</i>
          )}
          {renderSuffix()}
          {renderClearIcon()}
          {component}
        </div>
      )
    }

    return component
  }

export default NCInput