import React from 'react'
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom'
import { useAuth } from 'common/hooks/use-simple-auth'
// import { useAuth } from 'common/hooks/use-auth'
import Layout from 'components/layout'
import { checkifUserAllowedToAccess } from 'common/utils/permissionFunctions'
import UnauthorizedPage from '../common/UnathorizedPage'

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  permissions?: string[]
  roles?: string[]
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> =
  ({
     permissions,
    roles,
     component: PageComponent,
     ...props
   }) => {
    // const { user } = useAuth() || {}
    const {user} = useAuth()
    const location = useLocation()

    if (!user.email) {
      const loginPath = '/login'
      const redirectPath = location
        ? `${loginPath}?redirect=${location.pathname}${location.search}`
        : loginPath
      return <Redirect to={redirectPath}/>
    }

    const renderComponent = () => {
      if (!checkifUserAllowedToAccess(
        permissions || [],
        user.permissions,
        user.user_role,
        roles
      )) {
        return <UnauthorizedPage/>
      }
      return <PageComponent/>
    }

    return (
      <Layout>
        <Route {...props}>
          {renderComponent()}
        </Route>
      </Layout>
    )
  }

export default ProtectedRoute
