import React, { Suspense, useState } from 'react'
import styled from 'styled-components'
import Header from 'components/layout/header'
import { PageLoader } from '../ui/placeholder'
import SideBar from './side-bar'
import MainLayout from './main-layout'
import './layout.css'

const MainLoader = styled(PageLoader)`
  
`

interface Props {
}

export const Layout: React.FC<Props> = ({children}) => {
  const [expand, setExpand] = useState(false)

  const toggleExpand = () => setExpand(!expand)

  return (
    <>
      <Suspense
        fallback={<MainLoader/>}
      >
        <Header/>
        <MainLayout
          sideBar={
            <SideBar
              expand={expand}
              toggleExpand={toggleExpand}
            />
          }
        >
          <div className={`main-bar ${expand ? 'expand' : ''}`}>
            {children}
          </div>
        </MainLayout>
      </Suspense>
    </>
  )
}

export default Layout
