import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _set from 'lodash/set'
import _get from 'lodash/get'
import { AlertRuleI, ConditionI, IScheduleForm, NotificationMediumI } from '../store.types'

export const ACCOUNT_SETTING_TAB = 'account-settings'
export const CONTACT_INFORMATION_TAB = 'contact-information'
export const NOTIFICATION_RULES_TAB = 'notifications-rules'
export const SCHEDULE_REPORT_TAB = 'schedule-report'

const initialCondition: ConditionI = {
  clients_type: 'all',
  clients: [],
  condition_id: null,
  condition_name: 'Equals',
  field: 'Incident Title',
  value: ''
}

const initialNotificationMedium: NotificationMediumI = {
  medium_name: 'slack',
  notify_after: 0
}

export const all_days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]

export const week_days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday'
]

const initialAlertRule: AlertRuleI = {
  all_alerts: true,
  clients_type: 'all',
  clients: [],
  conditions: [initialCondition],
  occurrence_days: [],
  time_interval: 'everyday',
  day_time_start: '',
  start_date: null,
  end_date: null,
  notification_mediums: [],
  day_time_end: '',
  occurrence_every: 0,
  occurrence_type: '',
  rule_id: null,
  type: 'alert',
  user_name: ''
}

interface IInitialState {
  data: any
  loading: boolean
  notificationRules: {
    fields: string[],
    conditions: string[]
    alert: {
      loading: boolean
      data: AlertRuleI
    }
  },
  scheduleReport: {
    data: any[]
    scheduleForm: IScheduleForm
    filters: {
      pageid: number
      items: number
    }
  }
}

const initialScheduleForm: IScheduleForm = {
  description: '',
  active_schedule: true,
  end_after_time: false,
  end_date: '',
  frequency: 'daily',
  recipient_emails: [],
  schedule_time: '',
  service_id: '',
  start_date: '',
  subject: '',
  time_zone: 'UTC',
  user_notify_before: 60,
  user_notify_mediums: [],
  hh: '',
  mm: '',
  time_type: 'hours'
}

const initialState: IInitialState = {
  data: null,
  loading: false,
  notificationRules: {
    alert: {
      data: initialAlertRule,
      loading: false
    },
    conditions: [],
    fields: []
  },
  scheduleReport: {
    data: [],
    scheduleForm: initialScheduleForm,
    filters: {
      pageid: 1,
      items: 10
    }
  }
}

const ProfileReducers = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setData(state, action: PayloadAction<any>) {
      state.data = action.payload
    },
    setAlertData(state, action: PayloadAction<AlertRuleI>) {
      const {start_date, end_date} = action.payload
      if (start_date && end_date) {
        action.payload.start_date = new Date(action.payload.start_date || '')
        action.payload.end_date = new Date(action.payload.end_date || '')
      }
      state.notificationRules.alert.data = action.payload
    },
    setFillableData(state, action: PayloadAction<{ fields: string[], conditions: string[] }>) {
      state.notificationRules.fields = action.payload.fields
      state.notificationRules.conditions = action.payload.conditions
    },
    handleChangeRulePayload(state, action: PayloadAction<{ key: string, value: any }>) {
      const {key, value} = action.payload
      state.notificationRules.alert.data = {
        ...state.notificationRules.alert.data,
        [key]: value
      }
    },
    handleChangeCondition(state, action: PayloadAction<{ index: number, data: any }>) {
      const {index, data} = action.payload
      const payload = _get(state, `notificationRules.alert.data.conditions[${index}]`)
      _set(state, `notificationRules.alert.data.conditions[${index}]`, {
        ...payload,
        ...data
      })
    },
    handleAddCondition(state, action: PayloadAction<null>) {
      state.notificationRules.alert.data.conditions = [
        ...state.notificationRules.alert.data.conditions,
        initialCondition
      ]
    },
    handleRemoveCondition(state, action: PayloadAction<number>) {
      const _condition = state.notificationRules.alert.data.conditions.slice()
      _condition.splice(action.payload, 1)
      state.notificationRules.alert.data.conditions = _condition
    },
    handleAddThenRow(state, action: PayloadAction<null>) {
      state.notificationRules.alert.data.notification_mediums = [
        ...state.notificationRules.alert.data.notification_mediums,
        initialNotificationMedium
      ]
    },
    handleRemoveThenRow(state, action: PayloadAction<number>) { // index number
      const _mediums = state.notificationRules.alert.data.notification_mediums.slice()
      _mediums.splice(action.payload, 1)
      state.notificationRules.alert.data.notification_mediums = _mediums
    },
    handleThenChange(state, action: PayloadAction<{ index: number, data: any }>) {
      const {index, data} = action.payload
      const payload = _get(state, `notificationRules.alert.data.notification_mediums[${index}]`)
      _set(state, `notificationRules.alert.data.notification_mediums[${index}]`, {
        ...payload,
        ...data
      })
    },
    setScheduleReports(state, action: PayloadAction<any[]>) {
      state.scheduleReport.data = action.payload
    },
    handleScheduleFormChange(state, action: PayloadAction<any>) {
      state.scheduleReport.scheduleForm = {
        ...state.scheduleReport.scheduleForm,
        ...action.payload
      }
    },
    setInitialScheduleForm(state, action: PayloadAction) {
      state.scheduleReport.scheduleForm = {...initialScheduleForm}
    },
    setScheduleFormData(state, action: PayloadAction<any>) {
      state.scheduleReport.scheduleForm = {...action.payload}
    },
    handleChangeFilters(state, action: PayloadAction<{ name: string, value: any }>) {
      const {name, value} = action.payload
      state.scheduleReport.filters = {
        ...state.scheduleReport.filters,
        [name]: value
      }
    },
  }
})

export const {
  setData,
  setLoading,
  setAlertData,
  setFillableData,
  handleChangeRulePayload,
  handleAddCondition,
  handleRemoveCondition,
  handleChangeCondition,
  handleAddThenRow,
  handleRemoveThenRow,
  handleThenChange,
  setScheduleReports,
  handleScheduleFormChange,
  setInitialScheduleForm,
  setScheduleFormData
} = ProfileReducers.actions

export default ProfileReducers.reducer