import { ColumnType } from 'rc-table/es/interface'

// export interface IncidentsI {
//   incident_id: string
//   incident_number: string
//   incident_status: string
//   incident_summary: string
//   incident_created_at: string
//   service_name: string
//   incident_description: string
//   escalation_policy_summary: string
//   assigned_to: string
//   service_id: string
//   assigned_via: string
//   notes: string
//   alert_details: IAlertDetail
//   incident_details: IIncidentDetail
//   timeline: TimelineI[]
//   last_4_notes: any[]
//   similar_incident_notes: any[]
//   priority: string
//   urgency: string
//   severity: string
// }

export interface IncidentsI {
  number: string,
  title: string,
  status: string
  priority: string
  severity: string
  created_at: string
  updated_at: string
  client_name: string
  summary: any
  similar_incidents: SimilarIncidentsI[],
  notes: INotes[],
  assigned_to: string[]
  assigned_by: string
  has_note: 0 | 1
  client_id: string
  timeline?: any[]
  escalation_policy?: string
}

interface SimilarIncidentsI {
  created_at: string
  created_by: string
  incident_number: string
  note: string
}


export interface IIncidentDetail {
  status: string
  incident_created_at: string
  assigned_from: string
  assigned_to: string
  escalation_policy: string
}

export interface IAlertDetail {
  status: string
  to: string
  summary: string
  assigned_from: string
  created: string
  body: string
  previous_notes: []
}

export interface TimelineI {
  incident_number: number
  incident_id: string
  incident_status: string
  service_id: string
  service_name: string
  created_at: string
  created_by: string
  activity: string
  html_style: string
}

export interface ClientsI {
  id: string
  name: string
  description: string
  status: string
}

export interface UsersI {
  name: string
  id: string
  email: string
  role: string
  description: string
  job_title: string
  teams: TeamsI[]
}

export interface TeamsI {
  id: string
  type: string
  summary: string
  self: string
  html_url: string
}

export interface IServices {
  description: string
  id: string
  name: string
  status: string
}


///////**************Runbook types*****************/////////////

export interface IRunbookEditSection {
  id: string
  type: string
  text: string
  url?: string
  properties: {}
}

export interface IRunbookAlertAndResolution {
  id: string
  alert: string
  cause: string
  remediation: string
  priority: string
  business_impact: string
  tags: string[]
  custom_col_1?: string
  custom_col_2?: string
  custom_col_3?: string
  custom_col_4?: string
  custom_col_5?: string
  timestamp?: string
  index?: number
}

export interface IRunbookCustomSection {
  id: string
  name: string
  text: string
}

export interface IRunbookEscalation {
  id: string
  type: string
  text: string
}

export interface IRunbookLastUpdated {
  by: string
  at: string
  subject: string
}

export interface IRunbookColumns extends ColumnType<IRunbookAlertAndResolution> {
  order?: number
  visible: boolean
  default: boolean
  readonly?: boolean
}

export interface IRunbook {
  service_id: string
  status: string
  last_updated: IRunbookLastUpdated | null
  alerts_n_resolution: IRunbookAlertAndResolution[]
  isNew?: boolean
  total_count: number
  columns: IRunbookColumns[]
  runbook_version_id?: string
  details?: any
  service_name?: string
  version_id: string
  created_at?: string
  created_by?: string
  updated_by?: string
}

export interface IVersion {
  version: string | number
  version_id: string
  created_by: string
  updated_by: string
  created_at: string
  details: string
  status: string
}

export interface IRunbookLists {
  version_id: string,
  created_by: string
  updated_by: string
  service_id: string
  service_name: string
  created_at: string
  details: any,
  status: string
  has_draft: number
}

/* tslint:disable */
export enum MODE {
  EDIT = 'edit',
  VIEW = 'view',
  ADD = 'add'
}

/* tslint:disable */


/////**********************************************////////////


/////////////********** Integrated Docs Types*****///////


export interface IIntegratedDocs {
  created_at: string
  customer_account_id: number
  details: string
  documents_lists: IDocs[]
  service_id: string
  service_name: string
  status: string
  updated_at: string
  updated_by: string

}

export interface IDocs {
  document_id: string
  document_name: string
  document_link: string
  author: string
  updated_by: string
  created_at: string
  updated_at: string
  status: string
  description: string
}

export interface IDocument {
  action_type: string
  author: string
  created_at: string
  customer_account_id: number
  description: string
  document_content: string
  document_id: string
  document_length: number
  document_link: string
  document_name: string
  service_id: string
  service_name: string
  status: string
  updated_at: string
  updated_by: string
}


////////////*****Users & Roles page***************////////////

export interface IUser {
  alternate_contact: string
  alternate_contact_otp_details: string
  alternate_email: string
  company_name: string
  company_size: string
  contact: string
  contact_otp_details: string
  created_at: string
  customer_account_id: number
  permissions: string[]
  reporting_to: string
  service_id: string
  services: string[]
  services_id: string
  shift_days: string | string[]
  shift_time: string
  team_id: string
  team_name: string
  time_zone: string | null
  updated_at: string
  user_description: string
  user_email: string
  user_id: string
  user_job_title: string
  user_name: string
  user_role: string[]
  user_role_id: string
  user_status: string
  user_type: string
  user_profile_url: string
  group_name: string[]
}

export type RemediationSearchType = {
  alert: string
  cause: string
  incident_tags: string
  remediation: string
  remediation_tags: string[]
  similarity_score: number
}

////////////*****Notifications***************////////////

export enum NOTIFICATION {
  NEW_INCIDENT = 'new_incident',
  INCIDENT_ASSIGNED = 'incident_assigned',
  RUNBOOK_ADDED = 'runbook_added',
  RUNBOOK_CHANGED = 'runbook_changed',
  DOCUMENT_ADDED = 'document_added',
  DOCUMENT_CHANGED = 'document_changed',
  ALL_TRIGGERED_INCIDENTS = 'all_triggered_incidents',
}

export type TNew_incident_data = {
  incident_id: string
  incident_description: string
  created_at: string
}

export type TNew_runbook_data = {
  runbook_id: string
  runbook_name: string
  client: string
  created_at: string
}

export type TRunbook_changed = {
  runbook_id: string
  runbook_name: string
  client: string
  updated_at: string
}

export interface INotification {
  action_done: boolean
  client_id: string
  client_name: string
  customer_account_id: number
  is_seen: boolean
  notification_id: string
  notification_description: string
  notified_at: string
  status: string
  title: string
  type: NOTIFICATION
  type_id: string
  description: string
}

export interface INotificationStatus {
  notificaiton_id: string
  type: string,
  notification_count: number
}


/////////////*********** DataDog source Types **************//////////////////////////

export interface IDataDog {
  type: string;
  service_key: string;
  description: string;
  incident_key: null;
  details: IDetails;
  cef_details: ICefDetails;
  summary: string;
  client_url: string;
  client: string;
  details_omitted: boolean;
  body_omitted: boolean;
}

export interface ICefDetails {
  client: string;
  client_url: string;
  contexts: IContext[];
  creation_time: Date;
  description: string;
  details: IDetails;
  event_class: string;
  message: string;
  mutations: any[];
  service_group: string;
  severity: string;
  source_component: string;
  source_origin: string;
  version: string;
  details_omitted: boolean;
}

export interface IContext {
  type: string;
  src?: string;
  href: string;
  alt?: string;
  text?: string;
}

export interface IDetails {
  user: string | null;
  title: string;
  tags: string;
  query: string;
  priority: string;
  org: string;
  monitor_state: string;
  event_type: string;
  event_id: string;
  body: string;
}

///////////********************** End Datadog Types ***********///////////

///////////********************** Integrations Types ***********///////////

export interface IntegratedDataI {
  details: string
  integration_id: string
  integration_name: string
  integration_type: string
  integration_url: string
  service_id: string
  service_name: string
  enabled: 0 | 1
  created_at: string
}

export interface IntegrationCardI {
  title: string
  description: string
  icon: any
  id: string
}

///////////********************** End Integrations Types ***********///////////

///////////********************** Notification rules types ***********///////////

export interface AlertRuleI {
  rule_id?: number | null
  user_name: string
  type: 'alert',
  all_alerts: boolean
  clients?: string[]
  clients_type: 'all' | 'include' | 'exclude' | ''
  time_interval: 'everyday' | 'weekdays' | 'recurrence'
  conditions: ConditionI[],
  occurrence_days: string[], // send a list of the days
  occurrence_type: string, // not required, send it in recurrence
  occurrence_every: number | null, // not required, send it in recurrence
  day_time_start: string,
  day_time_end: string,
  end_date: Date | null | string
  start_date: Date | null | string,
  notification_mediums: NotificationMediumI[]
}

export interface ConditionI {
  condition_id?: number | null
  field: string
  condition_name: string
  value: string
  clients: string[]
  clients_type: 'include' | 'exclude' | 'all'
}

export interface NotificationMediumI {
  medium_id?: number,
  notify_after: number,
  medium_name: 'push-notification' | 'slack' | 'email' | 'in-app'
}

export interface INotes {
  created_at: string
  created_by: string
  note: {
    images: string[]
    message: string
  } 
}

export enum VIEW {
  LIST = 'list',
  GRID = 'grid'
}

export interface IScheduleForm {
  active_schedule: boolean
  description: string
  end_after_time: boolean
  end_date: string
  frequency: 'daily' | 'weekly' | 'monthly'
  recipient_emails: string[]
  schedule_time: string
  service_id: string
  start_date: string
  subject: string
  time_zone: string
  user_notify_before: number
  user_notify_mediums: string[]
  hh: string
  mm: string
  time_type: 'minutes' | 'hours'
}

export interface IScheduleFormPayload {
  "schedule_id"?: string
  "time_zone": string
  "frequency": "daily" | 'weekly' | 'monthly',
  "active_schedule": boolean, // if true then end_after_time should be false and don't provide end_date value
  "end_after_time": boolean, // if true then active_schedule should be false and you have to provide end_date value
  "start_date": string, //"2021-01-01"
  "schedule_time": string, //"10:30:00"
  "end_date": string, // "2022-01-01" provide it only when end_after_time is provided
  "service_id": string,
  "recipient_emails": string[],
  "subject": string,
  "description": string,
  "user_notify_before": number, // 60 # this is the number of minutes before we notify user
  "user_notify_mediums": string[] //["email", "in-app-notification", "slack"]
}


///////////////////-----Roater Scheduling--------------------/////////////////////

export enum ScheduleViewType {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month'
}
