import React from 'react'
import styled from 'styled-components'

const MainSection = styled.div`
   width: 100%;
   display: flex;
`

interface MainLayoutI {
  sideBar: React.ReactElement
}

const MainLayout: React.FC<MainLayoutI> = (props): React.ReactElement => {
  return (
    <MainSection>
      {props.sideBar}
      {props.children}
    </MainSection>
  )
}

export default MainLayout