import React from 'react'
import styled from 'styled-components'

interface IPageTitle {
  title: string
  secondTitle?: string
  className?: string
}

export const PageTitle: React.FC<IPageTitle> = ({ title, className, secondTitle }): React.ReactElement => {
  return (
    <TitleSection className={`nc-pageTitle ${className || ''}`}>
      <span className='title-text'>{title}</span>&nbsp;
      {secondTitle && <small>({secondTitle})</small>}
    </TitleSection>
  )
}

const TitleSection = styled.div`
 .title-text {
   font-size: 26px;
   font-weight: bold;
 }
`

export default PageTitle
