import React, { lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import ProtectedRoute from './protected-route'
import PublicRoute from './public-route'
import {
  ACCOUNT_OWNER,
  ADMIN, CLIENT_ADMIN, CLIENT_READ_ONLY,
  getAllRoles,
  getIncidentsPermissions,
  getRunbooksListsAccessPermission,
  getRunbookViewPermission, READ_ONLY, STANDARD_USER,
  VIEW_CLIENT_ANALYTICS,
  VIEW_STATUS_ANALYTICS,
  VIEW_TEAM_ANALYTICS,
  VIEW_USERS_ANALYTICS
} from 'common/utils/permissionFunctions'
import { retry } from 'common/utils/loadable'

const HomePage = lazy(() => retry(() => import('pages/home')))
const LoginPage = lazy(() => retry(() => import('pages/login')))
const SignUpPage = lazy(() => retry(() => import('pages/signup')))
const VerifyEmailPage = lazy(() => retry(() => import('pages/verify-email')))
const NotFoundPage = lazy(() => retry(() => import('pages/not-found')))
const ForgotPassword = lazy(() => retry(() => import('pages/verify-email/ForgotPassword')))
const Incidents = lazy(() => retry(() => import('pages/incidents')))
const incidentDetails = lazy(() => retry(() => import('pages/incidentDetails')))
const pastAcitivites = lazy(() => retry(() => import('pages/pastActivities')))
const RunbookPage = lazy(() => retry(() => import('pages/runbook')))
const EditRunbook = lazy(() => retry(() => import('pages/runbook/EditRunbook')))
const ResetPassword = lazy(() => import('pages/reset-password'))
const UsersAndGroups = lazy(() => retry(() => import('pages/users-groups')))
const Integrations = lazy(() => retry(() => import('pages/integrations')))
const IntegrationsFormSlot = lazy(() => retry(() => import('pages/integrations/integration-form-slot')))
const RefreshRoles = lazy(() => retry(() => import('pages/refresh')))
const Reports = lazy(() => retry(() => import('pages/reports')))
const RunbookDocs = lazy(() => retry(() => import('pages/runbook/runbook-docs')))
const MyProfile = lazy(() => retry(() => import('pages/my-profile')))
const OnCallSchedule = lazy(() => retry(() => import('pages/on-call-schedule')))
const AddEditSchedule = lazy(() => retry(() => import('pages/on-call-schedule/add-edit-schedule')))
const EscalationPolicy = lazy(() => retry(() => import('pages/escalation-policy')))
const AddEscalationPolicy = lazy(() => retry(() => import('pages/escalation-policy/components/add-edit-escalation')))
// const TestLayout  = lazy(() => retry(() => import('components/layout/test-layout')))

export type TAppProps = {
  [key: string]: any;
}

interface AppRoutesProps {
  appProps?: TAppProps;
}

export const Routes: React.FC<AppRoutesProps> = () => {
  return (
    <Switch>
      <PublicRoute exact={true} path='/' component={HomePage} />
      <ProtectedRoute
        roles={[CLIENT_ADMIN, CLIENT_READ_ONLY]}
        permissions={getIncidentsPermissions()}
        exact={true}
        path='/incidents'
        component={Incidents}
      />
      <ProtectedRoute
        permissions={[]}
        exact={true}
        path='/my-profile/:tab/:subtab?'
        component={MyProfile}
        roles={getAllRoles()}
      />
      <ProtectedRoute
        permissions={getIncidentsPermissions()}
        roles={[CLIENT_ADMIN, CLIENT_READ_ONLY]}
        exact={true}
        path='/incidents/:id'
        component={incidentDetails}
      />
      {/*<ProtectedRoute exact={true} path='/incidents/:id' component={incidentDetails} />*/}
      <ProtectedRoute
        exact={true}
        path='/pastAcitivites'
        component={pastAcitivites}
      />
      <ProtectedRoute
        exact={true}
        permissions={getRunbooksListsAccessPermission()}
        path='/nc-runbooks/:tab'
        component={RunbookPage}
      />
      <ProtectedRoute
        exact={true}
        permissions={[]}
        roles={[ADMIN, ACCOUNT_OWNER]}
        path='/nc-escalation-policy'
        component={EscalationPolicy}
      />
      <ProtectedRoute
        exact={true}
        permissions={[]}
        roles={[ADMIN, ACCOUNT_OWNER]}
        path='/nc-escalation-policy/:mode/:id?'
        component={AddEscalationPolicy}
      />
      <ProtectedRoute
        exact={true}
        permissions={getRunbookViewPermission()}
        path='/nc-runbooks/runbooks/:mode/:client_id?'
        component={EditRunbook}
      />
      <ProtectedRoute
        exact={true}
        permissions={getRunbookViewPermission()}
        path='/nc-runbooks/integrated-docs/:service_id/:mode/:document_id?'
        component={RunbookDocs}
      />
      <ProtectedRoute
        permissions={[]}
        roles={[ACCOUNT_OWNER, ADMIN]}
        exact={true}
        path='/users-roles/:tab'
        component={UsersAndGroups}
      />
      <ProtectedRoute
        roles={[ACCOUNT_OWNER, ADMIN]}
        exact={true}
        path='/integrations'
        component={Integrations}
      />
      <ProtectedRoute
        roles={[ACCOUNT_OWNER, ADMIN]}
        exact={true}
        path='/integrations/:mode/:type/:id?'
        component={IntegrationsFormSlot}
      />
      <ProtectedRoute
        roles={[ACCOUNT_OWNER, ADMIN]}
        exact={true}
        path='/on-call-schedule'
        component={OnCallSchedule}
      />

      <ProtectedRoute
        roles={[ACCOUNT_OWNER, ADMIN]}
        exact={true}
        path='/on-call-schedule/:mode/:id?'
        component={AddEditSchedule}
      />

      <ProtectedRoute
        permissions={
          [
            VIEW_STATUS_ANALYTICS,
            VIEW_USERS_ANALYTICS,
            VIEW_TEAM_ANALYTICS,
            VIEW_CLIENT_ANALYTICS
          ]
        }
        roles={[ACCOUNT_OWNER, ADMIN, STANDARD_USER, CLIENT_ADMIN, CLIENT_READ_ONLY, READ_ONLY]}
        exact={true}
        path='/dashboard'
        component={Reports}
      />
      <PublicRoute exact={true} path='/refresh/roles' component={RefreshRoles} />
      <PublicRoute exact={true} path='/login' component={LoginPage} />
      <PublicRoute exact={true} path='/signup/:user_id?' component={SignUpPage} />
      <PublicRoute exact={true} path='/forgot-password' component={ForgotPassword} />
      <PublicRoute exact={true} path='/verify-email' component={VerifyEmailPage} />
      <PublicRoute exact={true} path='/cnf-forgot-password' component={ResetPassword} />
      {/*<PublicRoute exact={true} path='/test-layout' component={TestLayout}/>*/}
      {/* Finally, catch all unmatched routes */}
      <Route component={NotFoundPage} />
    </Switch>
  )
}

export default Routes
