import React, { useEffect } from 'react'
import styled from 'styled-components'

const Headway = styled.span`
  display: flex;
  line-height: 35px;
`

export const WhatsNew = () => {

  useEffect(() => {
    const script = document.createElement('script')
    script.async = true
    script.src = 'https://cdn.headwayapp.co/widget.js'
    document.head.appendChild(script)
    const config = {
      selector: '.nc-headway-badge',
      account: 'J1OV0x'
    }
    script.onload = function () {
      // @ts-ignore
      if (window.Headway && window?.Headway.init) {
        // @ts-ignore
        window?.Headway?.init(config)
      }
    }
  }, [])

  return (
    <div>
      <Headway className='nc-headway-badge'>
        What's new?
      </Headway>
    </div>
  )
}

export default WhatsNew