import React from 'react'
import './input-group.css'

interface InputGroupProps {
  title?: string;
  style?: any
}

export const InputGroup: React.FC<InputGroupProps> = ({
  title,
  children,
  ...props
}) => {
  return (
    <div className='nc-input-group nc-loader-input' {...props}>
      {title && <div className='nd-input-group__title'>{title}</div>}
      {children}
    </div>
  )
}

export default InputGroup
