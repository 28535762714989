import React from 'react'
import cx from 'classnames'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export type TBreadcrumb = {
  title: string
  to: string
  onClick?: any
  active?: boolean
  readonly?: boolean
  className?: string
}

interface IBreadcrumb {
  data: TBreadcrumb[]
}

const SpanLink = styled.span`
  &:hover {
     text-decoration: underline;
     cursor: pointer
  }
`
const ReadOnlyLink = styled.span`
    color: #95a0af;
    pointer-events: none;
    cursor: default;
`

export const Breadcrumb: React.FC<IBreadcrumb> = (props): React.ReactElement => {
  const {data} = props

  const renderLink = (d: TBreadcrumb) => {

    if (d.readonly) {
      return <ReadOnlyLink>{d.title}</ReadOnlyLink>
    }

    if (typeof d.onClick === 'function')
      return <SpanLink onClick={d.onClick}>{d.title}</SpanLink>

    return <Link to={d.to} title={d.title}>{d.title}</Link>

  }

  return (
    <div className='nc-breadcrumbs'>
      <ul>
        {
          data.map((d: TBreadcrumb) => {
            return (
              <li
                key={d.title}
                className={cx('pointer', {
                  active: d.active
                })}
              >
                {renderLink(d)}
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default Breadcrumb
