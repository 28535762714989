import React from 'react'

interface INCContainer {
  className?: string
}

const NCContainer: React.FC<INCContainer> =
  ({
     children,
     className = 'nc-incidents'
   }): React.ReactElement => {
    return (
      <div className={`nc-content ${className}`}>
        <div className='nc-container-fluid'>
          {children}
        </div>
      </div>
    )
  }

export default NCContainer
