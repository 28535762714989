import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useAuth } from 'common/hooks/use-simple-auth'

interface ITimezone {
  date: string
  fromTimezone?: string
  to?: string
  format?: string
  fromNow?: boolean
  isIncidents?: boolean
}


const Timezone: React.FC<ITimezone> =
  ({
     date,
     format = 'YYYY-MM-DD LT',
     fromTimezone = 'GMT',
     fromNow,
     isIncidents
   }): React.ReactElement => {

    const [_date, setDate] = useState(date)
    const {user: {time_zone = moment.tz.guess()}, incidentTimezone} = useAuth()

    const getTime = () => {
      let tzone = time_zone || 'UTC'
      if (isIncidents) {
        tzone = incidentTimezone || tzone || moment.tz.guess()
      }
      if (fromNow)
        return moment(_date).tz(tzone).fromNow()

      return moment(_date).tz(tzone).format(format)
    }

    useEffect(() => {
      setDate(date)
    }, [date])

    return (
      <>{getTime()}</>
    )
  }

export default Timezone


