import React from 'react'
import { LoaderContainer } from '../../common'
import Spin from 'components/nCall/spin'

interface IPageLoader {
  className?: string
}

const PageLoader: React.FC<IPageLoader> = ({className}): React.ReactElement => (
  <LoaderContainer className={className || ''}>
    <Spin/>
  </LoaderContainer>
)

export default PageLoader
