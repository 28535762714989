import cx from 'classnames'
import React from 'react'
import Spin from 'components/nCall/spin'
import './button.css'

export type TButtonColor = 'blue' | 'grey' | 'teal' | 'green' | 'red'

export interface ButtonProps {
  color?: TButtonColor;
  loaderClass?: string;
  outline?: boolean;
  loading?: boolean;
  iconButton?: boolean;
  outlineColor?: string;
  full?: boolean;
  notAllowed?: boolean
  type?: 'button' | 'submit' | 'reset' | undefined;
}

export const Button: React.FC<ButtonProps &
  React.HTMLProps<HTMLButtonElement>> =
  ({
     className,
     children,
     onClick,
     color,
     style,
     outline,
     loading,
     disabled,
     full,
     type,
     iconButton,
     notAllowed,
     loaderClass
   }) => {
    const buttonClasses = cx(className, 'nc-button', {
      blue: color === 'blue',
      grey: color === 'grey',
      teal: color === 'teal',
      green: color === 'green',
      red: color === 'red',
      [`${color}-outline`]: outline,
      full: full,
      'nc-action-button': iconButton,
      disabled: disabled,
      'not-allowed': notAllowed
    })

    let component = (
      <button
        className={cx(buttonClasses)}
        onClick={onClick}
        disabled={disabled}
        style={style}
        type={type}
      >
        {children}
        {loading && <Spin small={true}/>}
      </button>
    )

    if (loading) {
      component = <div className={`nc-button-loading load-inline ${loaderClass}`}>{component}</div>
    }

    return component
  }

Button.defaultProps = {
  loading: false,
  outline: false,
}

export default Button
