import React, { Suspense } from 'react'
import moment from 'moment-timezone'
// import { hot } from 'react-hot-loader';
import { ToastContainer } from 'react-toastify'
import { BrowserRouter as Router } from 'react-router-dom'
import { PageLoader } from 'components/ui/placeholder'
import Routes from '../routes'
import ViewImage from '../ui/view-image'
import withClearCache from '../../ClearCache'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../rootReducer'
import { setImage, setMultiple } from 'store/reducers/misc.reducer'
import 'rc-pagination/assets/index.css'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.core.css'
import 'react-toastify/dist/ReactToastify.css'
import 'assets/css/nc-css/nc-incidents.css'
import 'assets/css/nc-css/nc-runbook.css'
import 'rc-tooltip/assets/bootstrap.css'
import 'react-phone-input-2/lib/style.css'
import 'rc-select/assets/index.less'
import './app.css'
import '../common/pagination/pagination.css'

moment.tz.setDefault('GMT')

const App: React.FC = () => {

  const dispatch = useDispatch()
  const {image, images, isMultiple} = useSelector((state: RootState) => state.misc)

  const onCloseImage = () => {
    dispatch(setImage(''))
    dispatch(setMultiple({images: [], isMultiple: false}))
  }

  return (
    <div className='App'>
      <Router>
        <Suspense fallback={<PageLoader/>}>
          <Routes/>
        </Suspense>
      </Router>
      <ToastContainer/>
      {(image || isMultiple) &&
      <ViewImage images={images} isMultiple={isMultiple} src={image} onClose={onCloseImage}/>
      }
    </div>
  )
}

const ClearCacheComponent = withClearCache(App)

// export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App;
export default ClearCacheComponent
