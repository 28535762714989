import cx from 'classnames'
import React from 'react'
import { ArrowDownIcon } from 'components/ui/icons'
import '../select/select.css'
import Spin from 'components/nCall/spin'
import styled from 'styled-components'

export interface SelectProps extends React.HTMLProps<HTMLSelectElement> {
  error?: string;
  loading?: boolean
  labelClass?: string
  extraBottom?: any
  className?: string
}

const LoadingContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 0;
`

const Select: React.FC<SelectProps> =
  ({
     label,
     error,
     prefix,
     required,
     placeholder,
     loading = false,
    extraBottom,
    className,
     children,
     ...props
   }) => {
  let component = (
    <div
      className={cx('nc-select-group', 'p-relative', className || null, {
        error
      })}
    >
      <select
        className={cx('nc-input', {
          error,
        })}
        {...props}
      >
        {placeholder && (
          <option value='' disabled={true}>
            {placeholder}
          </option>
        )}
        {children}
      </select>
      {
        loading ?
          <LoadingContainer>
            <Spin small={true}/>
          </LoadingContainer>
          :
          <div className='nc-caret'>
            <ArrowDownIcon/>
          </div>
      }
      {extraBottom}
    </div>
  )

  if (error) {
    component = (
      <>
        {component}
        <div className='error'>{error}</div>
      </>
    )
  }

  if (label) {
    component = (
      <div className='nc-input-group'>
        {label && (
          <label className={`nc-label ${props.labelClass}`}>
            {label}
            {required && <span style={{color: 'red'}}>{` `}*</span>}
          </label>
        )}
        <div className='nc-select-group'>{component}</div>
      </div>
    )
  }

  return component
}

Select.defaultProps = {
  type: 'text',
}

export default Select
